
import { computed, defineComponent } from '@nuxtjs/composition-api';

const bgClipCutClasses = {
  left: {
    xs: 'bg-cut-left-xs',
    md: 'bg-cut-left-md',
    lg: 'bg-cut-left-lg',
  },
  right: {
    xs: 'bg-cut-right-xs',
    md: 'bg-cut-right-md',
    lg: 'bg-cut-right-lg',
  },
  'left-right': {
    xs: 'bg-cut-left-right-xs',
    md: 'bg-cut-left-right-md',
    lg: 'bg-cut-left-right-lg',
  },
};

const bgColors = {
  'gray-dark': 'bg-gray-dark',
  blue: 'bg-blue',
  'gradient-red': 'bg-gradient-red-pink',
  pink: 'bg-pink',
  transparent: 'bg-transparent',
};

const bgHoverColors = {
  'gray-dark': 'group-hover/card:bg-gray-dark group-hover/card:bg-none',
  blue: 'group-hover/card:bg-blue group-hover/card:bg-none',
  'gradient-red': 'group-hover/card:bg-gradient-red-pink',
  pink: 'group-hover/card:bg-pink group-hover/card:bg-none',
  transparent: 'group-hover/card:bg-transparent group-hover/card:bg-none',
};

export default defineComponent({
  props: {
    cut: {
      type: String,
      default: null,
    },
    cutSize: {
      type: String,
      default: 'lg',
    },
    bg: {
      type: String,
      default: 'bg-dark',
    },
    borderColor: {
      type: String,
      default: 'gray-dark',
    },
    borderHoverColor: {
      type: String,
      default: '',
    },
    hovered: {
      type: Boolean,
      default: true,
    },
    hoveredBorderAnimation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const bgColor = computed(() => {
      return bgColors[props.borderColor];
    });

    const bgHoverColor = computed(() => {
      if (!props.borderHoverColor) {
        return '';
      }

      return bgHoverColors[props.borderHoverColor];
    });

    const cutCorner = computed(() => {
      if (!props.cut || props.cut === 'no-cut') return 'bg-clip-rectangle';

      return bgClipCutClasses[props.cut][props.cutSize];
    });

    return {
      bgColor,
      bgHoverColor,
      cutCorner,
    };
  },
});
